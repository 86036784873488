import React from 'react'
import Link from 'next/link'

import { useMediaQuery } from '@mantine/hooks'
import { useDisclosure } from '@mantine/hooks'

import BoatersListIcon from 'common/assets/svg/boaterslist-icon.svg'
import css from './Footer2024.module.css'
import { Group, Text } from '@mantine/core'

import { SocialMediaIcons } from '../SocialMediaIcons/SocialMediaIcons'
import ContactModal from 'components/AboutAndContact/ContactModal'

export default function Footer() {
  const isMobile = useMediaQuery(`(max-width: 30em)`)
  const [opened, { open, close }] = useDisclosure(false)

  const mobileLayout = (
    <Group
      className={css.mobileFooterLinks}
      style={{ justifyContent: 'space-between' }}
    >
      <Group>
        <Link href="/about-us" legacyBehavior>
          about
        </Link>

        <Text onClick={open}>contact</Text>
      </Group>
      <BoatersListIcon data-testid="allCategoriesIcon" width={40} height={25} />

      <SocialMediaIcons />
    </Group>
  )

  const desktopLayout = (
    <Group className={css.footerLinks}>
      <Group position="left">
        <SocialMediaIcons />
      </Group>
      <Group position="right">
        <Link href="/about-us" legacyBehavior>
          about
        </Link>

        <BoatersListIcon
          data-testid="allCategoriesIcon"
          width={40}
          height={25}
        />

        <Text onClick={open}>contact</Text>
      </Group>
    </Group>
  )
  return (
    <>
      {isMobile ? <>{mobileLayout}</> : <>{desktopLayout}</>}
      <ContactModal opened={opened} close={() => close()} />
    </>
  )
}
