import React from 'react'

import { useUser } from '@auth0/nextjs-auth0'
import { useDisclosure } from '@mantine/hooks'
import hasRole from 'common/utils/hasRole'

import Link from 'next/link'
import LoginButtonGroup from './LoginButtonGroup'
import NavLinks from './NavLinks'
import ContactModal from '../AboutAndContact/ContactModal'

import theme from '../../../common/theme'
import css from './SideBar.module.css'
import {
  MediaQuery,
  Button,
  SimpleGrid,
  Space,
  Paper,
  Stack,
  Text,
} from '@mantine/core'

export const SideBar = () => {
  const { user } = useUser()
  const isAdmin = hasRole(user, 'admin')
  const isTeamMember = hasRole(user, 'team-member')
  const isLocal = process.env.NODE_ENV
  const [contactModalOpen, { open, close }] = useDisclosure(false)

  const signedInOptions = (
    <>
      <Stack p="md">
        <Paper
          shadow="xs"
          p="md"
          withBorder
          m="sm"
          style={{ backgroundColor: theme.colors.brandBlue.concat('40') }}
        >
          <Text ta="center">Signed in as </Text>
          <Text ta="center"> {user && user.email}</Text>
        </Paper>

        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <SimpleGrid cols={3} style={{ justifyItems: 'center' }}>
            <NavLinks />
          </SimpleGrid>
        </MediaQuery>
      </Stack>

      <Stack p="lg">
        <Button
          style={{
            backgroundColor: `${theme.colors.brandBlue}`,
            color: `${theme.colors.brandWhite}`,
          }}
          className={css.navButtons}
          component={Link}
          href="/claim/newClaim"
        >
          Add Your Business
        </Button>
        <Button
          style={{
            backgroundColor: `${theme.colors.brandBlue}`,
            color: `${theme.colors.brandWhite}`,
          }}
          className={css.navButtons}
          component={Link}
          href="/info-landing"
        >
          View Business Options
        </Button>
        
        {isAdmin && (
          <>
            <Button
              style={{
                backgroundColor: `${theme.colors.brandBlue}`,
                color: `${theme.colors.brandWhite}`,
              }}
              className={css.navButtons}
              component={Link}
              href="/admin"
            >
              Admin
            </Button>
            <Button
              style={{
                backgroundColor: `${theme.colors.brandBlue}`,
                color: `${theme.colors.brandWhite}`,
              }}
              className={css.navButtons}
              component={Link}
              href="/knowledge"
            >
              Knowledge Library
            </Button>    
            <Button
              style={{
                backgroundColor: `${theme.colors.brandBlue}`,
                color: `${theme.colors.brandWhite}`,
              }}
              rel="noopener noreferrer"
              target="_blank"
              component={Link}
              className={css.navButtons}
              href={
                isLocal === 'development'
                  ? 'http://localhost:5601/app/home#/'
                  : 'https://search-boaterslist-prod-zt6xih7pbbmvpxoysdt4lef744.us-west-2.es.amazonaws.com/_dashboards'
              }
            >
              OpenSearch
            </Button>
          </>
        )}
        {(isAdmin || isTeamMember) && (
          <>
            <Button
              style={{
                backgroundColor: `${theme.colors.brandBlue}`,
                color: `${theme.colors.brandWhite}`,
              }}
              className={css.navButtons}
              component={Link}
              href="/dev-questions"
            >
              Development Form
            </Button>
          </>
        )}

        <Button
          style={{
            backgroundColor: `${theme.colors.brandBlue}`,
            color: `${theme.colors.brandWhite}`,
          }}
          onClick={open}
          className={css.navButtons}
        >
          Contact Us
        </Button>

        <Button
          style={{
            backgroundColor: `${theme.colors.brandYellow}`,
            color: `${theme.colors.brandWhite}`,
          }}
          component={Link}
          href="/api/auth/logout"
          className={css.navButtons}
        >
          Logout
        </Button>
      </Stack>
      <ContactModal opened={contactModalOpen} close={() => close()} />
    </>
  )

  const signedOutOptions = (
    <Stack p="md" mt="xl">
      <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
        <SimpleGrid cols={3} style={{ justifyItems: 'center' }}>
          <NavLinks />
        </SimpleGrid>
      </MediaQuery>
      <Space />
      <LoginButtonGroup />
    </Stack>
  )

  return <>{user ? <div>{signedInOptions}</div> : <> {signedOutOptions}</>}</>
}
