import React from 'react'
import Link from 'next/link'

import { useUser } from '@auth0/nextjs-auth0'

import theme from '../../../common/theme'
import logoHeader from 'common/assets/images/boaterslist-logo-horizontal-color-white.png'
import css from './Header2024.module.css'
import { Image, MediaQuery, Burger, Group } from '@mantine/core'

import LoginButtonGroup from './LoginButtonGroup'

export default function Header(props: any) {
  const { opened, handleOpen } = props
  const { user } = useUser()

  return (
    <>
      <Group
        onContextMenu={(e) => {
          e.preventDefault()
        }}
        onDragStart={(e) => {
          e.preventDefault()
        }}
        onSelect={(e) => {
          e.preventDefault()
        }}
        style={{ justifyContent: 'space-between' }}
      >
        <Group>
          <Link href="/">
            <Image
              src={logoHeader.src}
              alt="Logo boaterlist header"
              maw={240}
            />
          </Link>
          <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
            <Group className={css.headerLinks}>
              <Link
                href="https://thedock.boaterslist.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                Book It/Buy It
              </Link>

              <Link href="/articles">Articles &amp; Advice</Link>

              <Link
                href="https://thedock.boaterslist.com/u/65662e75-22fb-4a45-b3a8-e5f36c986fa1"
                rel="noopener noreferrer"
                target="_blank"
              >
                Merchandise
              </Link>
            </Group>
          </MediaQuery>
        </Group>

        {!user && (
          <MediaQuery smallerThan="lg" styles={{ display: 'none' }}>
            <Group className={css.buttonContainer}>
              <LoginButtonGroup />
            </Group>
          </MediaQuery>
        )}

        <MediaQuery
          largerThan="lg"
          styles={{ display: user ? 'visible' : 'none' }}
        >
          <Burger
            opened={opened}
            onClick={handleOpen}
            size="md"
            color={theme.colors.brandWhite}
            mr="xl"
          />
        </MediaQuery>
      </Group>
    </>
  )
}
