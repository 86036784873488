import React from 'react'

import Link from 'next/link'

import { FaShoppingBasket, FaBookOpen, FaShip } from 'react-icons/fa'
import { Group, Badge, ActionIcon } from '@mantine/core'
import css from './NavLinks.module.css'

export default function NavLinks() {
  const navIcons = [
    { link: '/articles', label: 'Articles', icon: <FaBookOpen size="2rem" /> },
    {
      link: 'https://thedock.boaterslist.com',
      label: 'MarketPlace',
      rel: 'noopener noreferrer',
      target: '_blank',
      icon: <FaShoppingBasket size="2rem" />,
    },
    {
      link: 'https://thedock.boaterslist.com/u/65662e75-22fb-4a45-b3a8-e5f36c986fa1',
      label: 'Merch',
      rel: 'noopener noreferrer',
      target: '_blank',
      icon: <FaShip size="2rem" />,
    },
  ]

  return (
    <>
      {navIcons.map((icon, indx) => {
        return (
          <Group style={{ flexDirection: 'column' }} key={indx}>
            <ActionIcon
              size="xl"
              variant="outline"
              color="yellow"
              className={css.icon}
              component={Link}
              href={icon.link}
              rel={icon.rel ? icon.rel : undefined}
              target={icon.target ? icon.target : undefined}
            >
              {icon.icon}
            </ActionIcon>
            <Badge color="cyan" variant="outline">
              {icon.label}
            </Badge>
          </Group>
        )
      })}
    </>
  )
}
