const theme = {
  colors: {
    brandBlueDark: '#0D213D',
    brandYellow: '#ebb112',
    brandWhite: '#FFFF',
    brandBlue: '#3798e2',
    brandBlack: '#000',
    brandOrange: '#FFB112',
    brandLightGray: '#d1d1d1',
    brandRedError: '#FF190C',
    brandGray: '#808080',
  },
  iconsUrl: {
    facebook: 'https://www.facebook.com/boaterslist',
    instagram: 'https://www.instagram.com/boaterslist/',
    youtube: 'https://www.youtube.com/channel/UCSJgkMlICEbYcsMfLlwxoNg',
    tiktok: 'https://www.tiktok.com/@boaters.list',
  },
  boaterstvUrl: {
    facebook: 'https://www.facebook.com/boaterslist.tv',
    instagram: 'https://www.instagram.com/boaterslist.tv/',
    tiktok: 'https://www.tiktok.com/@boaterslist.tv',
  },
  boatersspanishUrl: {
    facebook: 'https://m.facebook.com/BoatersListenespanol/',
    instagram: 'https://www.instagram.com/boaterslist_en_espanol/',
  },
  reelCooleUrl: {
    instagram: 'https://www.instagram.com/reel_coole/',
    tiktok: 'https://www.tiktok.com/@reel.coole',
  },
  hookedupUrl: {
    instagram: 'https://www.instagram.com/hooked__up_/',
  },
  tuglifeUrl: {
    instagram: 'https://www.instagram.com/__tuglife__/?hl=en',
  },
  badIdeaIconsUrl: {
    facebook: 'https://www.facebook.com/badideaboating',
    instagram: 'https://www.instagram.com/badideaboating/',
  },
  hauloverIconsUrl: {
    facebook: 'https://www.facebook.com/hauloverinlet',
    youtube: 'https://www.youtube.com/@BoatsvsHaulover',
  },
  locations: {
    titleSize: 18,
    titleWeight: '600',
    defaultSize: 14,
  },
  searchResults: {
    resultsMapHeight: 300,
  },
  locationsDetail: {
    titleSize: 35,
    detailSubtitleSize: 24,
    detailSimpleTextSize: 14,
    contactFont: 'bold',
    contactFontSize: 15,
  },
  locationsTab: {
    containerMargin: 10,
    sectionFontSize: 18,
    sectionMarginBottom: 5,
    aboutDefaultFontSize: 14,
  },
  boaterslistInfo: {
    titleFontSize: 36,
    titleFontWeight: '400',
    titleMarginBottom: 20,
    titleMarginTop: 20,
    subtitleFontSize: 24,
    subtitleMarginTop: 10,
    subtitleMarginBottom: 10,
    subtitleFontWeight: 'bold',
    textFontSize: 15,
    infoHeader: {
      titleSize: 24,
      color: '#FFFF',
      backgroundColor: '#3798e2',
      padding: 25,
      fontWeight: '500',
    },
  },
}

export default theme
