import React from 'react'
import Link from 'next/link'

import {
  Stack,
  Modal,
  Group,
  Button,
  Card,
  Avatar,
  Text,
  ActionIcon,
} from '@mantine/core'
import { FaDirections, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa'

export default function ContactModal(props: any) {
  const { opened, close } = props

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="Contact The Boaters List Crew!"
      >
        <Card withBorder padding="xl" radius="md" shadow="sm">
          <Card.Section
            h={140}
            style={{
              background:
                'url(https://www.datocms-assets.com/51196/1628616875-boat-rentals-2.jpg)',
              backgroundSize: 'cover ',
              backgroundPositionY: 'center',
            }}
          />
          <Avatar
            src="https://www.datocms-assets.com/51196/1629836252-boaterslist-logo-horizontal-color-white-5d32718cd0a6b13e568786c56c94a727.png"
            size={80}
            radius={80}
            mx="auto"
            mt={-30}
          />
          <Text ta="center" fz="lg" fw={500} mt="sm">
            Boaters List
          </Text>
          <Card.Section inheritPadding withBorder p="lg">
            <Group position="left">
              <ActionIcon color="cyan" variant="filled" size="lg">
                <FaPhoneAlt size="1.625rem" />
              </ActionIcon>

              <Text
                fz="sm"
                c="blue"
                td="underline"
                component={Link}
                href="tel:18883524242"
              >
                1-888-352-4242
              </Text>
            </Group>
            <Group position="left" mt="md">
              <ActionIcon color="cyan" variant="filled" size="lg">
                <FaWhatsapp size="1.625rem" />
              </ActionIcon>

              <Text
                fz="sm"
                c="blue"
                td="underline"
                component={Link}
                rel="noopener noreferrer"
                target="_blank"
                href="https://wa.me/12106206412"
              >
                210-620-6412
              </Text>
            </Group>

            <Group mt="md" position="left">
              <ActionIcon color="cyan" variant="filled" size="lg">
                <FaDirections size="1.625rem" />
              </ActionIcon>
              <Stack style={{ gap: '0' }}>
                <Text fz="sm" c="#535a62">
                  111 W Olmos Drive,
                </Text>
                <Text fz="sm" c="#535a62">
                  San Antonio, TX 78212
                </Text>
              </Stack>
            </Group>
          </Card.Section>

          <Button
            fullWidth
            radius="md"
            mt="xl"
            size="md"
            variant="outline"
            color="cyan"
            component={Link}
            href="mailto:better@boaterslist.com"
          >
            Email
          </Button>
        </Card>
      </Modal>
    </>
  )
}
