import React from 'react'
import {
  FaYoutubeSquare,
  FaInstagramSquare,
  FaFacebookSquare,
} from 'react-icons/fa'
import theme from '../../../common/theme'
import css from './SocialMediaIcons.module.css'
import { ActionIcon, Group } from '@mantine/core'

export function SocialMediaIcons() {
  return (
    <Group className={css.sicons}>
      <ActionIcon>
        <FaFacebookSquare
          style={{ color: theme.colors.brandBlue }}
          size="1.75rem"
          onClick={() => window.open(theme.iconsUrl.facebook, '_blank')}
        />
      </ActionIcon>

      <ActionIcon>
        <FaInstagramSquare
          style={{ color: theme.colors.brandBlue }}
          size="1.75rem"
          onClick={() => window.open(theme.iconsUrl.instagram, '_blank')}
        />
      </ActionIcon>

      <ActionIcon>
        <FaYoutubeSquare
          style={{ color: theme.colors.brandBlue }}
          size="1.75rem"
          onClick={() => window.open(theme.iconsUrl.youtube, '_blank')}
        />
      </ActionIcon>
    </Group>
  )
}
