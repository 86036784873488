import React, { useEffect, useState } from 'react'

import { useRouter } from 'next/router'
import { useWindowScroll } from '@mantine/hooks'
import { useMediaQuery } from '@mantine/hooks'
import { useSsr } from 'usehooks-ts'
import { usePathname } from 'next/navigation'
import { UserProvider } from '@auth0/nextjs-auth0'

import 'web-app/styles/globals.css'
import theme from 'common/theme'
import {
  AppShell,
  Header as MantineHeader,
  Footer as MantineFooter,
  Aside,
  useMantineTheme,
  MantineProvider,
  ScrollArea,
} from '@mantine/core'

import * as gtag from 'web-app/lib/gtag'
import Header2024 from 'components/Common/Header2024'
import Footer2024 from 'components/Common/Footer2024'
import PwaModal from 'components/Pwa/PwaModal'
import { SideBar } from 'components/Common/SideBar'
import type { AppProps } from 'next/app'
import { Analytics } from '@vercel/analytics/react'
import { RecoilRoot } from 'recoil'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const isServerSide = useSsr()
  const largeScreen = useMediaQuery('(min-width: 60em)')

  const [installModal, setInstallModal] = useState(false)
  const [opened, setOpened] = useState(false)
  let [defferedPrompt, setDefferedPrompt] = useState({})
  const [scroll, scrollTo] = useWindowScroll()
  const mantineTheme = useMantineTheme()
  const pathname = usePathname()

  isServerSide.isServer
    ? null
    : window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        setDefferedPrompt(e)
        handlePrompting()
      })

  const handlePrompting = () => {
    let currentDate = new Date()
    let userDenialDate = localStorage
      ? localStorage.getItem('userDeniedInstallDate')
      : false

    if ('standalone' in navigator && navigator.standalone) {
      console.log('Installed on iOS')
      return
    } else if (matchMedia('(display-mode: standalone)').matches) {
      console.log('Installed on Android or desktop')
      return
    } else {
      if (userDenialDate) {
        console.log('close date present')
        let denyDate = new Date(userDenialDate)
        let dateCompare = denyDate.getDate() + 3
        denyDate.setDate(dateCompare)
        denyDate < currentDate ? setInstallModal(true) : null
      } else
        setTimeout(() => {
          setInstallModal(true)
        }, 10000)
    }
  }

  useEffect(() => {
    opened
      ? (document.body.style.cssText = 'overflow: hidden')
      : (document.body.style.cssText = 'overflow: auto')
  }, [opened])

  React.useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js').then(
        function (registration) {
          console.log(
            'Service Worker registration successful with scope: ',
            registration.scope,
          )
        },
        function (err) {
          console.log('Service Worker registration failed: ', err)
        },
      )
    }

    const handleRouteChange = (url: string) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const handleClose = () => {
    setOpened(false)
    return
  }

  useEffect(() => {
    // close sidebar any time pathname changes
    handleClose()
  }, [pathname])

  // if (router.isFallback) {
  //   console.log('Router Load')
  //   return <div>Loading...</div>
  // }

  const handleOpen = () => {
    setOpened(!opened)
    return
  }

  return (
    <>
      <RecoilRoot>
        <UserProvider>
          <AppShell
            style={{
              backgroundColor: router.pathname === '/' ? '#0d213d' : 'white',
              border: 'none',
            }}
            header={
              <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                theme={{
                  breakpoints: {
                    xs: '30em',
                    sm: '49em',
                    md: '64em',
                    lg: '74em',
                    xl: '90em',
                  },
                }}
              >
                {opened ? (
                  <Aside
                    // hiddenBreakpoint="sm"

                    hidden={!opened}
                    width={{ sm: 300, lg: 300 }}
                    display={opened ? 'visible' : 'none'}
                  >
                    <ScrollArea type="hover">
                      <SideBar />
                    </ScrollArea>
                  </Aside>
                ) : null}

                <MantineHeader
                  height={{ base: 75, sm: 90 }}
                  p="md"
                  style={{
                    backgroundColor: theme.colors.brandBlueDark,
                    position: 'sticky',
                    top: '0px',
                  }}
                >
                  {<Header2024 opened={opened} handleOpen={handleOpen} />}
                </MantineHeader>
              </MantineProvider>
            }
            footer={
              //@ts-ignore
              <MantineFooter
                className="footer-style"
                height={{
                  base: router.pathname.includes('/location')
                    ? '5.625rem'
                    : scroll.y > 20
                    ? 0
                    : '3.325rem',
                  sm: router.pathname.includes('/location')
                    ? '4.225rem'
                    : scroll.y > 20
                    ? 0
                    : '4.225rem',
                }}
                p={{
                  base: router.pathname.includes('/location')
                    ? 'sm'
                    : scroll.y > 20
                    ? 0
                    : 'sm',
                  xs: router.pathname.includes('/location')
                    ? 'sm'
                    : scroll.y > 20
                    ? 0
                    : 'sm',
                }}
                style={{
                  position: largeScreen
                    ? 'sticky'
                    : router.pathname.includes('/location')
                    ? 'relative'
                    : 'fixed',
                }}
              >
                {<Footer2024 />}
              </MantineFooter>
            }
          >
            {installModal ? (
              <PwaModal
                defferedPrompt={defferedPrompt}
                openClose={installModal}
              />
            ) : null}

            <Component {...pageProps} />
            <Analytics />
          </AppShell>
        </UserProvider>
      </RecoilRoot>
    </>
  )
}

export default MyApp
