import React from 'react'
import { useDisclosure } from '@mantine/hooks'
import { Modal, Group, Button, Text, Title } from '@mantine/core'
import theme from 'common/theme'

type Props = {
  defferedPrompt: any
  openClose: boolean
}

export default function PwaModal(props: Props) {
  const [opened, { open, close }] = useDisclosure(props.openClose)

  const currentTime = new Date()
  const date = currentTime.toDateString()

  const handleClick = () => {
    props.defferedPrompt.prompt()
    props.defferedPrompt.userChoice.then((choice: any) => {
      if (choice.outcome === 'dismissed') {
        localStorage.setItem('userDeniedInstallDate', date)
      }
    })
    close()
  }

  //we add timestamp to localstorage so we don't show install prompt again for 3 days
  const addTimeToLocal = () => {
    localStorage.setItem('userDeniedInstallDate', date)

    close()
  }

  return (
    <>
      <Modal
        size="auto"
        withCloseButton={false}
        opened={opened}
        onClose={addTimeToLocal}
        styles={{
          body: { backgroundColor: theme.colors.brandBlue },
          header: {
            backgroundColor: theme.colors.brandBlue,
          },
          title: { color: theme.colors.brandWhite },
        }}
      >
        <Modal.Overlay onClick={addTimeToLocal} zIndex={-1} opacity={0.1} />
        <Title order={3} c={theme.colors.brandWhite} align="center">
          Add To Homescreen
        </Title>
        <Text c={theme.colors.brandWhite} align="center" mt="sm" fz="sm">
          Boaters List Works Great As A Standalone App!
        </Text>
        <Group position="center" mt="md">
          <Button
            styles={{
              root: {
                '&:hover': {
                  backgroundColor: '#C9CFCF',
                },
              },
            }}
            variant="default"
            c={theme.colors.brandBlue}
            onClick={() => addTimeToLocal()}
          >
            Close
          </Button>
          <Button
            styles={{
              root: {
                '&:hover': {
                  backgroundColor: '#C9CFCF',
                },
              },
            }}
            variant="default"
            c={theme.colors.brandBlue}
            onClick={() => handleClick()}
          >
            Install
          </Button>
        </Group>
      </Modal>
    </>
  )
}
