import React from 'react'

import { useRouter } from 'next/router'
import theme from '../../../common/theme'
import css from './LoginButtonGroup.module.css'
import { Button } from '@mantine/core'

export default function LoginButtonGroup() {
  const router = useRouter()
  return (
    <>
      <Button
        className={css.loginButtons}
        style={{
          backgroundColor: `${theme.colors.brandBlue}`,
          color: `${theme.colors.brandWhite}`,
        }}
        title={'User Sign Up'}
        onClick={() => router.push('/api/auth/signup')}
      >
        User Sign Up
      </Button>

      {router?.pathname !== '/info-landing' ? (
        <Button
          className={css.loginButtons}
          style={{
            backgroundColor: `${theme.colors.brandBlue}`,
            color: `${theme.colors.brandWhite}`,
          }}
          title={'Business Sign Up'}
          onClick={() => router.push('/info-landing')}
        >
          Business Sign Up
        </Button>
      ) : null}

      <Button
        className={css.loginButtons}
        style={{
          backgroundColor: `${theme.colors.brandYellow}`,
          color: `${theme.colors.brandWhite}`,
          border: `2px solid ${theme.colors.brandYellow}`,
        }}
        title={'Log In'}
        onClick={() => router.push('/api/auth/login')}
      >
        Log In
      </Button>
    </>
  )
}
